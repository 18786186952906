import { Link } from 'react-router-dom';
import { Box, Button, Heading2 } from '@whosaidtrue/ui';

const SetUpGame: React.FC = () => {
  return (
    <Box className="flex flex-col justify-center items-center !p-6">
      <div
        className="absolute inset-0 bg-repeat opacity-30 pointer-events-none"
        style={{
          backgroundImage: "url('./assets/bg.svg')",
          backgroundSize: '40%',
        }}
      ></div>
      <Heading2 className="mb-5" style={{ textShadow: '0 0 10px white' }}>
        Start a Game
      </Heading2>
      <Button as={Link} to="/decks">
        Get Started!
      </Button>
    </Box>
  );
};

export default SetUpGame;
