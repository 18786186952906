import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useAppSelector } from '../../app/hooks';
import { selectAccessCode, selectPlayerName } from '../game/gameSlice';
import InGameNav from './InGameNav';
import LargeNav from './LargeNav';
import logo from '@logo/logo.png';

const NavBar: React.FC = () => {
  const accessCode = useAppSelector(selectAccessCode);
  const playerName = useAppSelector(selectPlayerName);
  const location = useLocation();
  const isInGame = playerName || accessCode;

  return (
    <nav
      className={classNames(
        'flex flex-row h-20 items-center mb-4 overscroll-contain px-5 rounded-b-3xl w-full sm:mb-6 lg:mb-8',
        location.pathname === '/' && !isInGame
          ? 'bg-transparent justify-end'
          : 'bg-purple-subtle-fill justify-between'
      )}
    >
      <Link
        className={location.pathname === '/' && !isInGame ? 'sr-only' : ''}
        to="/"
      >
        <img className="block w-14 h-14" src={logo} alt="Who Said True?!" />
      </Link>
      {isInGame ? <InGameNav /> : <LargeNav />}
    </nav>
  );
};

export default NavBar;
