import React from 'react';
import tw from 'tailwind-styled-components';
import loudspeaker from '../assets/loudspeaker.png';
import Button from '../button/Button';
import { AiOutlineClose } from 'react-icons/ai';

const Container = tw.div`
    shadow-md
    py-4
    sm:px-5
    px-2
    bg-white
    text-center
    sm:w-max
    w-full
    text-headline
    font-bold
    flex
    flex-col
    sm:flex-row
    sm:items-center
    gap-2
    sm:gap-4
    items-center
    rounded-xl
`

interface TakingTooLongProps {
    onPressClose: () => void;
    handler: () => void;
}

/**
 * A message modal that displays a button for the host to skip to results
 *
 * @param handler function that triggers the skip to results event
 * @returns
 */
const TakingTooLong: React.FC<TakingTooLongProps> = ({
    handler,
    onPressClose
}) => {
    return (
        <Container>
            <div className="sm:flex sm:flex-row sm:items-center">
                <img
                    src={loudspeaker}
                    className="inline-block mr-4"
                    alt="loudspeaker"
                    width="25px"
                    height="25px"
                />

                <div>
                    If users are taking too long, you can skip them
                </div>
            </div>

            <Button buttonStyle="inline" onClick={handler}>
                Skip to results
            </Button>

            <AiOutlineClose color='#8f3d3d' className='absolute right-2 top-2 z-10 text-2xl z-10 cursor-pointer sm:relative sm:top-0 sm:right-0' onClick={onPressClose} />
        </Container>
    )
}

export default TakingTooLong;
