import { Button } from '@whosaidtrue/ui';
import { BiCog } from 'react-icons/bi';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAccessCode, selectPlayerName } from '../game/gameSlice';
import { setFullModal } from '../modal/modalSlice';

const InGameNav: React.FC = () => {
  const dispatch = useAppDispatch();

  const name = useAppSelector(selectPlayerName);
  const accessCode = useAppSelector(selectAccessCode);

  const openGameOptions = () => {
    dispatch(setFullModal('gameOptions'));
  };

  // Note: Show Player Name or Game Code
  return (
    <>
      <h2 className="text-basic-black font-extrabold relative mx-auto text-center text-lg sm:text-2xl leading-tight">
        {name ? (
          name
        ) : (
          <>
            Game Code: <span className="uppercase">{accessCode}</span>
          </>
        )}
      </h2>
      <Button
        type="button"
        buttonStyle="small"
        $secondary
        onClick={openGameOptions}
      >
        <BiCog className="w-6 h-6 sm:hidden" />
        <span className="sr-only sm:not-sr-only">Game Options</span>
      </Button>
    </>
  );
};

export default InGameNav;
