import jwt from 'jwt-decode';
import { TokenPayload } from '@whosaidtrue/api-interfaces';
import { Bundle, Deck } from '@whosaidtrue/app-interfaces';

// This just makes it so the type doesn't have to be re-specified every time
export function decodeUserToken(token: string): { user: TokenPayload } {
  return jwt(token);
}

// express a guess value as a percentage of the total number of players
export function guessAsPercentage(guess: number, totalPlayers: number): string {
  return `${Math.round(100 * (guess / totalPlayers))}%`;
}

// circular index selection from arrays
export function arrayItemAtIndexCircular<T>(items: T[], index: number): T {
  return items[index % items.length];
}

export function sortBySortOrder(decks: { sort_order: number }[]) {
  return decks.sort((a, b) => a.sort_order < b.sort_order ? -1 : 1);
}

export const isBundle = (item: Deck | Bundle): item is Bundle => {
  return "position" in item && "num_credits" in item && "description2" in item;
};

export const isTwoMonthsAgo = (timestamp: number) => {
  const TWO_MONTHS_MS = 2 * 30 * 24 * 60 * 60 * 1000;
  return timestamp < (Date.now() - TWO_MONTHS_MS);
};

export const isWithinTwoHours = (time: number | Date | null) => {
  if (!time) {
    return false;
  }

  const ts = (time instanceof Date) ? time.getTime() : time;
  const TWO_HOURS_MS = 2 * 60 * 60 * 1000;
  return (Date.now() - TWO_HOURS_MS) < ts;
};