import classNames from 'classnames';

const Box: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...rest
}) => {
  const componentClassName = classNames(
    'relative bg-white border-0 overflow-hidden p-8 rounded-3xl',
    {},
    className
  );
  return <div className={componentClassName} {...rest} />;
};

export default Box;
