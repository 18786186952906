import { useEffect, useState } from 'react';
import Button from '../../button/Button';
import CelebrationIcons from '../../celebration-icons/CelebrationIcons';
import ModalContent from '../../containers/ModalContent';
import './winner.css';

export interface WinnerAnnouncementProps {
  name: string;
  onRequestClose?: () => void;
}

const WinnerAnnouncement: React.FC<WinnerAnnouncementProps> = ({
  name,
  onRequestClose,
}) => {
  const [countDownOver, setCountdownOver] = useState(false);

  const isTie = name.includes('&');

  useEffect(() => {
    const timer = setTimeout(() => setCountdownOver(true), 2800);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <ModalContent
      className="bg-purple-subtle-fill flex flex-col justify-center h-32rem text-center sm:h-36rem"
      $narrow
    >
      {!countDownOver && (
        <div className="font-black flex flex-col gap-2 text-purple-base text-6xl sm:gap-4 sm:text-8xl">
          <div>And</div>
          <div>the</div>
          <div>{isTie ? 'Winners' : 'Winner'}</div>
          <div className="wnr-announce-txt">{isTie ? '  Are' : ' Is'}</div>
        </div>
      )}
      {countDownOver && (
        <div className="font-black">
          <div className="text-3xl mb-6">{name}!</div>
          <CelebrationIcons />
          <div className="mt-8">
            <Button onClick={onRequestClose}>See Final Scores</Button>
          </div>
        </div>
      )}
    </ModalContent>
  );
};

export default WinnerAnnouncement;
