import tw from 'tailwind-styled-components';
import * as React from "react";
import Divider from "../lib/divider/Divider";

export const GameCode = tw.h1`
self-center
text-center
font-extrabold
text-3xl
xs:text-4xl
tracking-[3px]
uppercase
my-4
`;

export const Heading1 = tw.h1`
self-center
text-center
font-bold
text-3xl
xs:text-4xl
my-4
`;

export const Heading2 = tw.h1`
self-center
text-center
font-bold
text-2xl
xs:text-3xl
my-3
`;

export const Heading3 = tw.h3`
self-center
text-center
font-bold
text-xl
xs:text-2xl
my-2
`;

export const Heading4 = tw.h4`
self-center
text-center
font-bold
text-base
xs:text-xl
my-1
`;

export const BodyNormal = tw.span`
self-center
text-base
xs:text-xl
`;

export const BodyLargeV2 = tw.span`
self-center
text-xl
xs:text-2xl
`;

export const BodyXLarge = tw.span`
self-center
text-2xl
xs:text-3xl
`;

export const Question = tw.span`
self-center
text-center
italic
text-xl
xs:text-2xl
`;

export const QuestionBoxContainer = tw.div`
self-center
text-center
rounded-3xl
bg-blue-subtle 
border 
border-blue-light 
border-solid 
p-4
xs:p-5
"`;

export const Separator = ({className = ''}: { className?: string }) =>
    <div className={className + ' my-[10px] px-[40px]'}>
        <div className='border-2 border-purple-base border-solid rounded self-center'/>
    </div>;

export type QuestionBoxProps = {
    heading1?: string;
    heading2?: string;
    question: string;
}
export const QuestionBox = ({heading1, heading2, question}: QuestionBoxProps) =>
    <QuestionBoxContainer>
        {heading1 && <Heading1 className="pb-2.5">{heading1}</Heading1>}
        {heading2 && <Heading2 className="pb-2.5 text-blue-base">{heading2}<br/></Heading2>}
        <Question
            dangerouslySetInnerHTML={{
                __html: `“${question}”`,
            }}/>
    </QuestionBoxContainer>;


export const Meanwhile = ({text, className = ''}: { text: string, className?: string }) =>
    <div className={className + ' self-center'}>
        <p className='underline italic text-center text-base xs:text-xl font-kalam font-bold'>
            Meanwhile...
        </p>
        <p className='italic text-center text-base xs:text-xl px-12 pt-2 xs:pt-3 font-kalam leading-tight'>
            {text}
        </p>
    </div>;

export const OrDivider = () =>
    <div className="flex items-center justify-center gap-3 h-8 mx-auto">
        <Divider dividerColor="grey"/>
        <Heading4>OR</Heading4>
        <Divider dividerColor="grey"/>
    </div>;