export const PLAYER_JOINED_GAME = 'PlayerJoinedGame';
export const ONE_LINERS = 'OneLiners';
export const PLAYER_LEFT_GAME = 'PlayerLeftGame';
export const REMOVE_PLAYER = 'RemovePlayer';
export const UPDATE_INACTIVE = 'UpdateInactivePlayers';
export const SET_QUESTION_STATE = 'SetQuestionState';
export const SET_QUESTION_STATE_RESYNC = 'SetQuestionStateResync';
export const SET_QUESTION_RESULTS = 'SetQuestionResult';
export const ANSWER_PART_1 = 'AnswerPart1';
export const ANSWER_PART_2 = 'AnswerPart2';
export const SET_GAME_STATE = 'SetGameState';
export const SET_HAVE_NOT_ANSWERED = 'SetHaveNotAnswered';
export const SET_READER = 'SetReader';
export const SET_CURRENT_PLAYERS = 'SetCurrentPlayers';
export const GAME_NOT_FOUND = 'GameNotFound';
export const START_GAME = 'StartGame';
export const END_GAME = 'EndGame';
export const UPDATE_GAME_STATUS = 'UpdateGameStatus';
export const QUESTION_END = 'QuestionEnd';
export const GAME_END = 'GameEnd';
export const MOVE_TO_QUESTION_RESULTS = 'MoveToQuestionResults';
export const HOST_BACK_TO_ANSWER = 'HostBackToAnswer';
export const HOST_TAKE_OVER_READING = 'HostTakeOverReading';
export const SKIP_QUESTION = 'SkipQuestion';
export const GAME_END_NO_ANNOUNCE = 'EndGameNoAnnounce';
export const MOVE_TO_ANSWER = 'MoveToAnswer';
export const START_NEXT_QUESTION = 'StartNextQuestion';
export const GAME_FINISHED = 'GameFinished';
export const HOST_LEFT_NO_RESULTS = 'HostLeftNoResults';
export const HOST_LEFT = 'HostLeft';
export const FUN_FACTS = 'FunFacts';
export const FETCH_MOST_SIMILAR = 'FetchMostSimilar';
