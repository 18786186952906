import { Button, Heading1, Heading2 } from '@whosaidtrue/ui';
import React, { MouseEvent, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import logo from '@logo/logo.png';
import { logout, selectIsGuest } from '../../features';
import Faqs from '../../features/faqs/Faqs';
// import GameVersions from '../../features/game-versions/GameVersions';
import HomeCarousel from '../../features/home-carousel/HomeCarousel';
import JoinGame from '../../features/join-game/JoinGame';
import Page from '../../features/page/Page';
import SetUpGame from '../../features/setup-game/SetupGame';

const Home: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isGuest = useAppSelector(selectIsGuest);
  const playRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // if any guest users get here, log them out
    if (isGuest) {
      dispatch(logout());
    }
  }, [dispatch, isGuest, history]);

  const handleLetsPlayClick = (event: MouseEvent) => {
    const { current } = playRef;

    if (current && !current.contains(event.target as Node)) {
      const { offsetTop } = current;

      window.scroll({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Page title="Home">
      <div className="block max-w-[10rem] xs:max-w-[14rem] mb-10 mx-auto">
        <img className="w-full h-full" src={logo} alt="Who Said True?!" />
      </div>

      <div className="max-w-3xl text-center mb-10 mx-auto">
        <Heading1 className="text-yellow-base">
          The outrageous game of quirky questions and anonymous answers
        </Heading1>
      </div>

      <div
        className="grid grid-cols-1 gap-6 max-w-6xl mb-20 mx-auto lg:grid-cols-2 lg:gap-10"
        ref={playRef}
      >
        <JoinGame />
        <SetUpGame />
      </div>

      <div className="max-w-6xl mb-20 mx-auto">
        <Heading2 className="text-yellow-base mb-4 text-center lg:mb-8">
          How to play Who Said True?!
        </Heading2>
        <HomeCarousel onLetsPlayClick={handleLetsPlayClick} />
      </div>

      {/* <div className="max-w-full mb-20 mx-auto">
        <GameVersions onLetsPlayClick={handleLetsPlayClick} />
      </div> */}

      <div className="max-w-3xl mb-10 mx-auto lg:mb-20">
        <Faqs />
      </div>

      <div className="max-w-6xl mb-6 mx-auto">
        <div className="bg-basic-black bg-opacity-75 rounded-3xl p-6 text-center flex flex-col items-center lg:p-10">
          <Heading2 className="text-yellow-base mb-4 lg:mb-8">
            Grab your friends and get ready to laugh!
          </Heading2>
          <Button className="block" onClick={handleLetsPlayClick}>
            Let's Play
          </Button>
        </div>
      </div>
    </Page>
  );
};

export default Home;
