import classNames from 'classnames';
import Box from './box/Box';

export interface ModalContentProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  $narrow?: boolean;
}

const ModalContent: React.FC<ModalContentProps> = ({
  children,
  className,
  $narrow,
}) => {
  const componentClassName = classNames(
    'max-w-full outline-none focus:outline-none',
    {
      'w-28rem': $narrow,
      'w-[580px]': !$narrow,
    },
    className
  );

  return <Box className={componentClassName}>{children}</Box>;
};

export default ModalContent;
