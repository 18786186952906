import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { isLoggedIn } from '../auth/authSlice';

const GuardedRoute: React.FC<RouteProps> = (props) => {
  const { children, exact, location, path } = props;
  const loggedIn = useAppSelector(isLoggedIn);

  return loggedIn ? (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  ) : (
    <Redirect
      to={`/login${
        location &&
        '?redirect=' +
          encodeURIComponent(location.pathname) +
          encodeURIComponent(location.search)
      }`}
    />
  );
};
export default GuardedRoute;
