import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Box, Heading2 } from '@whosaidtrue/ui';
import { StatusRequestResponse } from '@whosaidtrue/api-interfaces';
import { initialRequest } from '../game/gameSlice';
import { api } from '../../api';
import { useAppDispatch } from '../../app/hooks';

const JoinGame: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const joinButtonRef = useRef(null);
  const [errorText, setErrorText] = useState('');

  const formik = useFormik({
    initialValues: {
      accessCode: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      accessCode: Yup.string()
        .length(4, 'Game Code must be 4 characters')
        .required('Game Code must be 4 characters'),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          const statusResponse = await api.get<StatusRequestResponse>(
            `/games/status?access_code=${values.accessCode}`
          );
          const { status } = statusResponse.data;

          if (status === 'finished') {
            setErrorText(
              'The game you are trying to join has already finished.'
            );
          } else {
            dispatch(initialRequest(values.accessCode));
            history.push(`/game/${values.accessCode}`);
          }
        } catch (e) {
          if (e.message.match(/code 403/)) {
            setErrorText('That Game Code has expired. Please start a new one.');
            return;
          }
          setErrorText('That Game Code is not valid. Please try again.');
        }
      }
    },
  });

  return (
    <Box className="flex flex-col justify-center items-center !p-6">
      <div
        className="absolute inset-0 bg-repeat opacity-30 pointer-events-none"
        style={{
          backgroundImage: "url('./assets/bg.svg')",
          backgroundSize: '40%',
        }}
      ></div>
      <Heading2 className="mb-5" style={{ textShadow: '0 0 10px white' }}>
        Join a Game
      </Heading2>
      <form
        className="flex flex-wrap items-center justify-center gap-6 max-w-sm lg:flex-nowrap lg:max-w-none"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <input
          className="block bg-purple-subtle-fill border-2 border-purple-base h-16 font-extrabold rounded shadow-md text-center text-4xl tracking-widest uppercase w-full focus:border-pink-base focus:outline-none"
          maxLength={4}
          pattern="[a-zA-Z0-9]*"
          required
          type="text"
          aria-label="Access code field"
          style={{ maxWidth: 200 }}
          {...formik.getFieldProps(`accessCode`)}
        />
        <Button
          className="block flex-shrink-0"
          ref={joinButtonRef}
          type="submit"
        >
          Join Game!
        </Button>
      </form>
      <div className="font-bold text-center text-lg text-destructive mt-4">
        {errorText || formik.errors.accessCode}
      </div>
    </Box>
  );
};

export default JoinGame;
