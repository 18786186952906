import { lazy, Suspense } from 'react';
import { Modal, ModalContent } from '@whosaidtrue/ui';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Loading from '../loading/Loading';
import WinnerAnnouncement from './full-screen-modals/WinnerAnnouncement';
import { selectFullModal, setFullModal } from './modalSlice';

// Lazy load modals
const RemovalNotification = lazy(
  () => import('./full-screen-modals/RemovalNotification')
);
const Checkout = lazy(() => import('./full-screen-modals/CardCheckout'));
const ChoosePaymentMethod = lazy(
  () => import('./full-screen-modals/ChoosePaymentMethod')
);
const DeckDetailsModal = lazy(() => import('../decks/DeckDetailsModal'));
const BundleDetailsModal = lazy(() => import('../decks/BundleDetailsModal'));
const ConfirmFreeCreditPurchase = lazy(
  () => import('./full-screen-modals/ConfirmFreeCreditPurchase')
);
const PreGameAuth = lazy(() => import('./full-screen-modals/PreGameAuth'));
const Login = lazy(() => import('../auth/Login'));
const CreateAccount = lazy(() => import('../auth/CreateAccount'));
const ConfirmEndGameModal = lazy(
  () => import('./full-screen-modals/ConfirmEndGameModal')
);
const RemovePlayersModal = lazy(
  () => import('./full-screen-modals/RemovePlayersModal')
);
const ReportAnIssueModal = lazy(
  () => import('./full-screen-modals/ReportAnIssueModal')
);
const ConfirmRemovePlayerModal = lazy(
  () => import('./full-screen-modals/ConfirmRemovePlayerModal')
);
const GameOptionsModal = lazy(
  () => import('./full-screen-modals/GameOptionsModal')
);
const ConfirmTakeOverReading = lazy(
  () => import('./full-screen-modals/ConfirmTakeOverReading')
);
const ConfirmSkipQuestion = lazy(
  () => import('./full-screen-modals/ConfirmSkipQuestion')
);
const AlreadyReceivedCredits = lazy(
  () => import('./full-screen-modals/AlreadyReceivedCredit')
);
const CheckYourEmail = lazy(
  () => import('./full-screen-modals/CheckYourEmail')
);
const SubmitQuestion = lazy(
  () => import('./full-screen-modals/SubmitQuestion')
);
const Profile = lazy(() => import('./full-screen-modals/Profile'));
const ChangePassword = lazy(
  () => import('./full-screen-modals/ChangePassword')
);
const Feedback = lazy(() => import('./full-screen-modals/Feedback'));
const Community = lazy(() => import('./full-screen-modals/Community'));
const Merchandise = lazy(() => import('./full-screen-modals/Merchandise'));
const LogOut = lazy(() => import('./full-screen-modals/LogOut'));
const Skip = lazy(() => import('./full-screen-modals/Skip'));

/**
 * All full screen modals render from here. This makes it possible to
 * open and close modals without having to re-render other components
 * in the current page, and makes it easier to control what modals are showing
 * and when.
 *
 */
const FullScreenModalController = () => {
  const dispatch = useAppDispatch();
  const currentModal = useAppSelector(selectFullModal);

  const close = () => {
    dispatch(setFullModal(''));
  };

  return (
    <>
      {/* Deck Details */}
      {currentModal === 'deckDetails' && (
          <Modal
              isOpen={currentModal === 'deckDetails'}
              onRequestClose={close}
              shouldCloseOnOverlayClick={true}
          >
            <Suspense fallback={<Loading />}>
              <DeckDetailsModal />
            </Suspense>
          </Modal>
      )}

      {/* Bundle Details */}
      {currentModal === 'bundleDetails' && (
          <Modal
              isOpen={currentModal === 'bundleDetails'}
              onRequestClose={close}
              shouldCloseOnOverlayClick={true}
          >
            <Suspense fallback={<Loading />}>
              <BundleDetailsModal />
            </Suspense>
          </Modal>
      )}

      {/* Choose Payment Method */}
      {currentModal === 'choosePaymentMethod' && (
        <Modal
          isOpen={currentModal === 'choosePaymentMethod'}
          onRequestClose={close}
          shouldCloseOnOverlayClick={true}
        >
          <Suspense fallback={<Loading />}>
            <ChoosePaymentMethod />
          </Suspense>
        </Modal>
      )}

      {/* Credit card */}
      {currentModal === 'cardCheckout' && (
        <Modal
          isOpen={currentModal === 'cardCheckout'}
          onRequestClose={close}
          shouldCloseOnOverlayClick={false}
        >
          <Suspense fallback={<Loading />}>
            <Checkout />
          </Suspense>
        </Modal>
      )}

      {/* Free Deck Credit Purchase confirm*/}
      {currentModal === 'freeCreditPurchase' && (
        <Modal
          isOpen={currentModal === 'freeCreditPurchase'}
          onRequestClose={close}
          shouldCloseOnOverlayClick={true}
        >
          <Suspense fallback={<Loading />}>
            <ConfirmFreeCreditPurchase />
          </Suspense>
        </Modal>
      )}

      {/* pre-game auth (user isn't logged and tries to start a game with a free deck)*/}
      {currentModal === 'preGameAuth' && (
        <Modal
          isOpen={currentModal === 'preGameAuth'}
          onRequestClose={close}
          shouldCloseOnOverlayClick={true}
        >
          <Suspense fallback={<Loading />}>
            <PreGameAuth />
          </Suspense>
        </Modal>
      )}

      {currentModal === 'login' && (
        <Modal isOpen={currentModal === 'login'} onRequestClose={close}>
          <ModalContent $narrow>
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          </ModalContent>
        </Modal>
      )}

      {currentModal === 'createAccount' && (
        <Modal isOpen={currentModal === 'createAccount'} onRequestClose={close}>
          <ModalContent $narrow>
            <Suspense fallback={<Loading />}>
              <CreateAccount />
            </Suspense>
          </ModalContent>
        </Modal>
      )}

      {/* Game Options */}
      {currentModal === 'gameOptions' && (
        <Modal isOpen={currentModal === 'gameOptions'} onRequestClose={close}>
          <Suspense fallback={<Loading />}>
            <GameOptionsModal />
          </Suspense>
        </Modal>
      )}

      {/* Confirm End Game */}
      {currentModal === 'confirmEndGame' && (
        <Modal
          isOpen={currentModal === 'confirmEndGame'}
          onRequestClose={close}
        >
          <Suspense fallback={<Loading />}>
            <ConfirmEndGameModal />
          </Suspense>
        </Modal>
      )}

      {/* Remove Players */}
      {currentModal === 'removePlayers' && (
        <Modal isOpen={currentModal === 'removePlayers'} onRequestClose={close}>
          <Suspense fallback={<Loading />}>
            <RemovePlayersModal />
          </Suspense>
        </Modal>
      )}

      {/* Confirm Remove Player */}
      {currentModal === 'confirmRemovePlayer' && (
        <Modal
          isOpen={currentModal === 'confirmRemovePlayer'}
          onRequestClose={close}
        >
          <Suspense fallback={<Loading />}>
            <ConfirmRemovePlayerModal />
          </Suspense>
        </Modal>
      )}

      {/* Removed From Game Notification */}
      {currentModal === 'removedFromGame' && (
        <Modal
          isOpen={currentModal === 'removedFromGame'}
          hideClose={true}
          onRequestClose={close}
        >
          <Suspense fallback={<Loading />}>
            <RemovalNotification />
          </Suspense>
        </Modal>
      )}

      {/* Report an Issue */}
      {currentModal === 'reportAnIssue' && (
        <Modal isOpen={currentModal === 'reportAnIssue'} onRequestClose={close}>
          <Suspense fallback={<Loading />}>
            <ReportAnIssueModal />
          </Suspense>
        </Modal>
      )}

      {/* Confirm skip question */}
      {currentModal === 'confirmSkipQuestion' && (
        <Modal
          isOpen={currentModal === 'confirmSkipQuestion'}
          onRequestClose={close}
        >
          <Suspense fallback={<Loading />}>
            <ConfirmSkipQuestion />
          </Suspense>
        </Modal>
      )}

      {/* Confirm take over reading */}
      {currentModal === 'confirmTakeOverReading' && (
        <Modal
          isOpen={currentModal === 'confirmTakeOverReading'}
          onRequestClose={close}
        >
          <Suspense fallback={<Loading />}>
            <ConfirmTakeOverReading />
          </Suspense>
        </Modal>
      )}

      {/* Winner announcement */}
      {currentModal === 'announceWinner' && (
        <Modal
          isOpen={currentModal === 'announceWinner'}
          onRequestClose={close}
        >
          <Suspense fallback={<Loading />}>
            <WinnerAnnouncement />
          </Suspense>
        </Modal>
      )}

      {/* Check your email */}
      {currentModal === 'checkYourEmail' && (
        <Modal
          isOpen={currentModal === 'checkYourEmail'}
          onRequestClose={close}
        >
          <Suspense fallback={<Loading />}>
            <CheckYourEmail />
          </Suspense>
        </Modal>
      )}

      {/* Already Received Credit */}
      {currentModal === 'freeCreditEmailInUseError' && (
        <Modal
          isOpen={currentModal === 'freeCreditEmailInUseError'}
          onRequestClose={close}
        >
          <Suspense fallback={<Loading />}>
            <AlreadyReceivedCredits />
          </Suspense>
        </Modal>
      )}

      {/* Submit question */}
      {currentModal === 'submitQuestion' && (
        <Modal
          isOpen={currentModal === 'submitQuestion'}
          onRequestClose={close}
        >
          <Suspense fallback={<Loading />}>
            <SubmitQuestion />
          </Suspense>
        </Modal>
      )}

      {/* Profile */}
      {currentModal === 'profile' && (
        <Modal isOpen={currentModal === 'profile'} onRequestClose={close}>
          <Suspense fallback={<Loading />}>
            <Profile />
          </Suspense>
        </Modal>
      )}

      {/* Change Password */}
      {currentModal === 'changePassword' && (
        <Modal
          isOpen={currentModal === 'changePassword'}
          onRequestClose={close}
        >
          <Suspense fallback={<Loading />}>
            <ChangePassword />
          </Suspense>
        </Modal>
      )}

      {/* Feedback */}
      {currentModal === 'feedback' && (
        <Modal isOpen={currentModal === 'feedback'} onRequestClose={close}>
          <Suspense fallback={<Loading />}>
            <Feedback />
          </Suspense>
        </Modal>
      )}

      {/* Community */}
      {currentModal === 'community' && (
        <Modal isOpen={currentModal === 'community'} onRequestClose={close}>
          <Suspense fallback={<Loading />}>
            <Community />
          </Suspense>
        </Modal>
      )}

      {/* Merchandise */}
      {currentModal === 'merchandise' && (
        <Modal isOpen={currentModal === 'merchandise'} onRequestClose={close}>
          <Suspense fallback={<Loading />}>
            <Merchandise />
          </Suspense>
        </Modal>
      )}

      {/* Log Out */}
      {currentModal === 'logOut' && (
        <Modal isOpen={currentModal === 'logOut'} onRequestClose={close}>
          <Suspense fallback={<Loading />}>
            <LogOut />
          </Suspense>
        </Modal>
      )}

      {/* Skip to Results */}
      {currentModal === 'skipToResults' && (
        <Modal isOpen={currentModal === 'skipToResults'} onRequestClose={close}>
          <Suspense fallback={<Loading />}>
            <Skip />
          </Suspense>
        </Modal>
      )}
    </>
  );
};

export default FullScreenModalController;
