import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';

export interface PageProps {
  className?: string;
  title: string;
}

const Page: React.FC<PageProps> = ({ className, children, title }) => {
  const componentClassName = classNames(
    'relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8',
    {},
    className
  );

  return (
    <>
      <Helmet>
        {/* TODO: move 'Who Said True?!' to ENV variable? */}
        <title>Who Said True?! - {title}</title>
      </Helmet>
      <div className={componentClassName}>{children}</div>
    </>
  );
};

export default Page;
