import {
  createSlice,
  createSelector,
  PayloadAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {CreateGameResponse, JoinGameResponse} from '@whosaidtrue/api-interfaces';
import {
  Deck,
  UserGameStatus,
  PlayerRef,
  GameStatus, Bundle,
} from '@whosaidtrue/app-interfaces';
import { api } from '../../api';
import omit from 'lodash.omit';

// local imports
import { RootState } from '../../app/store';

export interface GameState {
  gameStatus: GameStatus | '';
  gameCreatedAt: string;
  hasRatedApp: boolean;
  playerStatus: UserGameStatus;
  hasPassed: boolean;
  passedQuestionId: number;
  gameToken: string;
  gameId: number;
  deck: Deck;
  bundle: Bundle;
  totalQuestions: number;
  isHost: boolean;
  hostName: string;
  players: Record<number, PlayerRef>;
  access_code: string;
  playerId: number;
  playerName: string;
  winner: string;
}

export const initialGameState: GameState = {
  gameStatus: '',
  gameCreatedAt: '',
  playerStatus: 'notInGame',
  gameToken: '',
  hasRatedApp: false,
  hasPassed: false,
  passedQuestionId: 0,
  gameId: 0,
  deck: {
    id: 0,
    name: '',
    sort_order: 0,
    clean: false,
    age_rating: 0,
    status: 'active',
    description: '',
    movie_rating: 'PG',
    sfw: true,
    thumbnail_url: '',
    purchase_price: '',
  },
  bundle: {
    id: 0,
    name: '',
    position: 0,
    movie_rating: null,
    status: 'active',
    num_credits: 0,
    description: '',
    description2: '',
    thumbnail_url: '',
    purchase_price: ''
  },
  totalQuestions: 0,
  isHost: false,
  access_code: '',
  players: {},
  hostName: '',
  playerName: '',
  playerId: 0,
  winner: '',
};

/**
 * Used to end a game from the API. This is useful
 * when a game
 */
export const endGameFromApi = createAsyncThunk<void, number>(
  'game/endGameFromApi',
  async (gameId) => {
    await api.patch('/games/end', { gameId });
  }
);

export const gameSlice = createSlice({
  name: 'game',
  initialState: initialGameState,
  reducers: {
    clearGame: () => {
      return initialGameState;
    },
    setGameStatus: (state, action) => {
      state.gameStatus = action.payload;
    },
    setPlayerStatus: (state, action: PayloadAction<UserGameStatus>) => {
      state.playerStatus = action.payload;
    },
    setGameDeck: (state, action) => {
      state.deck = action.payload;
    },
    setBundle: (state, action) => {
      state.bundle = action.payload;
    },
    setPlayerName: (state, action) => {
      state.playerName = action.payload;
    },
    setHasPassed: (state, action) => {
      state.hasPassed = action.payload;
    },
    setPassedQuestionId: (state, action) => {
      state.passedQuestionId = action.payload;
    },
    // TODO: investigate issue in SocketProvider.tsx
    // NOTE: not ideal having a condition inside reducer
    // NOTE: this is a temporary workaround
    refundPass: (state, action) => {
      if (action.payload === state.passedQuestionId) {
        state.hasPassed = false;
        state.passedQuestionId = 0;
      }
    },
    initialRequest: (state, action) => {
      state.access_code = action.payload;
      state.playerStatus = 'choosingName';
    },
    addPlayer: (state, action) => {
      const { id } = action.payload;
      state.players = { ...state.players, [id]: action.payload };
    },
    removePlayer: (state, action: PayloadAction<number>) => {
      state.players = omit(state.players, action.payload);
    },
    createGame: (state, action: PayloadAction<CreateGameResponse>) => {
      state.gameId = action.payload.game_id;
      state.access_code = action.payload.access_code;
      state.gameCreatedAt = action.payload.created_at.toString();
      state.isHost = true;
      state.playerStatus = 'gameCreateSuccess';
    },
    endGame: (state) => {
      state.gameStatus = 'postGame';
    },
    setHasRatedApp: (state, action) => {
      state.hasRatedApp = action.payload;
    },
    removeFromGame: (state) => {
      state.playerStatus = 'removed';
    },
    setPlayers: (state, action: PayloadAction<{ players: PlayerRef[] }>) => {
      const { players } = action.payload;
      state.players = players.reduce(
        (acc: Record<number, PlayerRef>, player: PlayerRef) => {
          return { ...acc, [player.id]: player };
        },
        {}
      );
    },
    joinGame: (state, action: PayloadAction<JoinGameResponse>) => {
      const {
        status,
        gameId,
        deck,
        totalQuestions,
        hostName,
        access_code,
        playerId,
        playerName,
        gameToken,
        createdAt,
      } = action.payload;

      state.gameStatus = status;
      state.gameCreatedAt = createdAt.toString();
      state.playerStatus = 'lobby';
      state.gameToken = gameToken;
      state.gameId = gameId;
      state.deck = deck;
      state.totalQuestions = totalQuestions;
      state.hostName = hostName;
      state.access_code = access_code;
      state.playerId = playerId;
      state.playerName = playerName;
    },
  },
});

// actions
export const {
  removeFromGame,
  setPlayerName,
  initialRequest,
  setGameStatus,
  clearGame,
  setGameDeck,
  setBundle,
  createGame,
  addPlayer,
  removePlayer,
  joinGame,
  setPlayers,
  setPlayerStatus,
  setHasPassed,
  setPassedQuestionId,
  refundPass,
  endGame,
} = gameSlice.actions;

// selectors
export const selectPlayerName = (state: RootState) => state.game.playerName;
export const selectIsHost = (state: RootState) => state.game.isHost;
export const selectHasPassed = (state: RootState) => state.game.hasPassed;
export const selectPassedQuestionId = (state: RootState) =>
  state.game.passedQuestionId;
export const selectGameId = (state: RootState) => state.game.gameId;
export const selectGameToken = (state: RootState) => state.game.gameToken;
export const selectGameStatus = (state: RootState) => state.game.gameStatus;
export const selectGameCreatedAt = (state: RootState) => state.game.gameCreatedAt ? new Date(state.game.gameCreatedAt) : null;
export const selectAccessCode = (state: RootState) => state.game.access_code;
export const selectGameDeck = (state: RootState) => state.game.deck;
export const selectBundle = (state: RootState) => state.game.bundle;
export const selectPlayerId = (state: RootState) => state.game.playerId;
export const selectPlayerStatus = (state: RootState) => state.game.playerStatus;
export const selectPlayers = (state: RootState) => state.game.players;
export const selectTotalQuestions = (state: RootState) =>
  state.game.totalQuestions;

export const selectPlayerList = createSelector(selectPlayers, (players) => {
  return Object.values(players);
});

// return an array containing the ids of all connected players.
// This is used to compare with the array of players that haven't answered yet.
// The difference between the two gives the number of players that have answered among
// the list of players still connected to the game
export const selectPlayerIds = createSelector(selectPlayerList, (players) => {
  return players.map((player) => player.id);
});

export const selectNumPlayersInGame = (state: RootState) =>
  Object.keys(state.game.players).length;

export default gameSlice.reducer;
