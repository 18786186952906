import * as React from "react";
import Modal from 'react-modal';
import {AiOutlineClose} from "react-icons/ai";

import './Modal.css';

export interface ModalProps extends Modal.Props {
    className?: string;
    isOpen: boolean;
    hideClose?: boolean;
    closeColor?: string;
    onRequestClose?: () => void;
};

/**
 * React modal component used to display all full screen modal components.
 */
const WstModal: React.FC<ModalProps> = ({
    children,
    className,
    onRequestClose,
    hideClose,
    closeColor,
    ...rest
}) => {
    return (
        <Modal
          className={className}
          closeTimeoutMS={1000}
          onRequestClose={onRequestClose}
          overlayClassName={className ? `${className}__Overlay` : undefined}
          shouldFocusAfterRender={false}
          {...rest}
        >
            {!hideClose && <AiOutlineClose color={closeColor} className='absolute top-5 right-5 text-2xl z-10 cursor-pointer' onClick={onRequestClose} />}
            {children}
        </Modal>
    );
};

export default WstModal;
