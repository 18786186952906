import Faqs from '../../features/faqs/Faqs';
import Page from '../../features/page/Page';

const Faq: React.FC = () => {
  return (
    <Page title="FAQs">
      <Faqs />
    </Page>
  );
};

export default Faq;
