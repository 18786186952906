import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Bundle, Deck} from '@whosaidtrue/app-interfaces';
import {RootState} from "../../app/store";

export interface CartState {
    deck: Deck | null;
    bundle: Bundle | null;
    priceInCents: number;
    clientSecret: string; // stripe payment intent secret
};

export const initialState: CartState = {
    deck: null,
    bundle: null,
    priceInCents: 0,
    clientSecret: ''
};

// convert "$2.00" -> 200
const toCents = (price: string) => parseFloat(price.replace(/[^0-9]/g, ""));

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        clearCart: () => {
            return initialState
        },
        addDeckToCart: (state, action: PayloadAction<Deck>) => {
            state.deck = action.payload;
            state.bundle = null;
            state.priceInCents = toCents(state.deck.purchase_price);
        },
        addBundleToCart: (state, action: PayloadAction<Bundle>) => {
            state.bundle = action.payload;
            state.deck = null;
            state.priceInCents = toCents(state.bundle.purchase_price);
        },
        setClientSecret: (state, action: PayloadAction<string>) => {
            state.clientSecret = action.payload;
        }
    },

})

export const {
    addDeckToCart,
    addBundleToCart,
    clearCart,
    setClientSecret
} = cartSlice.actions;

// selectors
export const selectCartDeck = (state: RootState) => state.cart.deck;
export const selectCartBundle = (state: RootState) => state.cart.bundle;
export const selectClientSecret = (state: RootState) => state.cart.clientSecret;
export const selectPriceInCents = (state: RootState) => state.cart.priceInCents;

export default cartSlice.reducer;
