import {createAsyncThunk, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {DeckSelectionResponse} from "@whosaidtrue/api-interfaces";
import {Bundle, Deck} from "@whosaidtrue/app-interfaces";
import {api} from '../../api';
import {showError} from "../modal/modalSlice";
import {sortBySortOrder} from '../../util/functions';
import {isForSchools} from "../../app/app";

export type DeckSet = 'all' | 'sfw' | 'PG' | 'PG13' | 'R' | 'NC17';

export interface DeckState {
    currentSetName: DeckSet;
    owned: Deck[];
    notOwned: Deck[];
    bundles: Bundle[];
    ownedByRating: Record<DeckSet, Deck[]>;
    notOwnedByRating: Record<DeckSet, Deck[]>;
    selectedDeck: Deck;
    selectedBundle: Bundle;
    isSelectedOwned: boolean;
}

export const initialState: DeckState = {
    currentSetName: 'all',
    owned: [],
    notOwned: [],
    bundles: [],
    ownedByRating: {
        "all": [],
        "PG": [],
        "NC17": [],
        "PG13": [],
        "R": [],
        "sfw": []
    },
    notOwnedByRating: {
        "all": [],
        "PG": [],
        "NC17": [],
        "PG13": [],
        "R": [],
        "sfw": []
    },
    selectedDeck: {
        id: 0,
        name: '',
        sort_order: 0,
        clean: false,
        age_rating: 0,
        status: 'active',
        description: '',
        movie_rating: 'PG',
        sfw: true,
        thumbnail_url: '',
        purchase_price: '',
        sample_question: ''
    },
    selectedBundle: {
        id: 0,
        name: '',
        position: 0,
        movie_rating: null,
        status: 'active',
        num_credits: 0,
        description: '',
        description2: '',
        thumbnail_url: '',
        purchase_price: ''
    },
    isSelectedOwned: false
};

export const getDeckSelection = createAsyncThunk(
    'decks/getSelection',
    async (_, {dispatch, rejectWithValue}) => {

        // build time var determines whether returned decks are all clean or all not clean
        const url = `/decks/selection?clean=${isForSchools}`;

        return api.get<DeckSelectionResponse>(url).then(res => {
            return res.data;
        }).catch(e => {
            console.error(e)
            dispatch(showError("Oops, something went wrong. Please try again later."));
            return rejectWithValue(e.message);
        })

    }
)

export const deckSlice = createSlice({
    name: 'decks',
    initialState,
    reducers: {
        clearDecks: () => {
            return initialState;
        },

        setCurrentSet: (state, action: PayloadAction<DeckSet>) => {
            state.currentSetName = action.payload;
        },
        setSelectedDeck: (state, action) => {
            state.selectedDeck = action.payload.deck;
            state.isSelectedOwned = action.payload.isOwned;
        },
        setSelectedBundle: (state, action) => {
            state.selectedBundle = action.payload.bundle;
        },
        clearSelectedDeck: (state) => {
            state.selectedDeck = initialState.selectedDeck;
            state.isSelectedOwned = false
        },
        clearSelectedBundle: (state) => {
            state.selectedBundle = initialState.selectedBundle;
        }
    },
    extraReducers: (builder) => {
        // success
        builder.addCase(getDeckSelection.fulfilled, (state, action) => {
            const {owned, notOwned, bundles} = action.payload;
            state.owned = owned;
            state.notOwned = notOwned;
            state.bundles = bundles;

            const ownedSfw: Deck[] = [];
            const ownedR: Deck[] = [];
            const ownedPG: Deck[] = [];
            const ownedPG13: Deck[] = [];
            const ownedNC17: Deck[] = [];

            owned.forEach((deck: Deck) => {
                if (deck.sfw) {
                    ownedSfw.push(deck);
                }
                switch (deck.movie_rating) {
                    case 'NC17':
                        ownedNC17.push(deck);
                        break;
                    case 'R':
                        ownedR.push(deck);
                        break;
                    case 'PG13':
                        ownedPG13.push(deck);
                        break;
                    case 'PG':
                        ownedPG.push(deck);
                        break;
                }

            })

            const notOwnedSfw: Deck[] = [];
            const notOwnedR: Deck[] = [];
            const notOwnedPG: Deck[] = [];
            const notOwnedPG13: Deck[] = [];
            const notOwnedNC17: Deck[] = [];

            notOwned.forEach((deck: Deck) => {
                if (deck.sfw) {
                    notOwnedSfw.push(deck);
                }

                switch (deck.movie_rating) {
                    case 'NC17':
                        notOwnedNC17.push(deck);
                        break;
                    case 'R':
                        notOwnedR.push(deck);
                        break;
                    case 'PG13':
                        notOwnedPG13.push(deck);
                        break;
                    case 'PG':
                        notOwnedPG.push(deck);
                        break;
                }
            })

            state.ownedByRating.all = owned;
            state.ownedByRating.NC17 = ownedNC17;
            state.ownedByRating.PG = ownedPG;
            state.ownedByRating.PG13 = ownedPG13;
            state.ownedByRating.R = ownedR;
            state.ownedByRating.sfw = ownedSfw;

            state.notOwnedByRating.all = notOwned;
            state.notOwnedByRating.NC17 = notOwnedNC17;
            state.notOwnedByRating.PG = notOwnedPG;
            state.notOwnedByRating.PG13 = notOwnedPG13;
            state.notOwnedByRating.R = notOwnedR;
            state.notOwnedByRating.sfw = notOwnedSfw;

        })
    }
})

// actions
export const {
    clearDecks,
    setSelectedDeck,
    setSelectedBundle,
    clearSelectedDeck,
    clearSelectedBundle,
    setCurrentSet
} = deckSlice.actions;

// selectors
export const selectOwned = (state: RootState) => state.decks.owned;
export const selectNotOwned = (state: RootState) => state.decks.notOwned;
export const getSelectedDeck = (state: RootState) => state.decks.selectedDeck;
export const getSelectedBundle = (state: RootState) => state.decks.selectedBundle;
export const selectIsOwned = (state: RootState) => state.decks.isSelectedOwned;
export const selectCurrentSetName = (state: RootState) => state.decks.currentSetName;
export const selectOwnedByRating = (state: RootState) => state.decks.ownedByRating;
export const selectNotOwnedByRating = (state: RootState) => state.decks.notOwnedByRating;
export const selectAllBundles = (state: RootState) => state.decks.bundles;

export const selectCurrentOwned = createSelector([selectCurrentSetName, selectOwnedByRating], (rating, decks) => {
    return sortBySortOrder(decks[rating].slice()) as Deck[];
});

export const selectCurrentNotOwned = createSelector([selectCurrentSetName, selectNotOwnedByRating], (rating, decks) => {
    return sortBySortOrder(decks[rating].slice()) as Deck[];
});

export const selectCurrentBundles = createSelector([selectCurrentSetName, selectAllBundles], (rating, bundles) => {
    return bundles.filter(b => b.movie_rating === rating || !b.movie_rating);
});

// default
export default deckSlice.reducer;
